import _Vue from 'vue'
import Badge from '../modules/common/components/argon/Badge.vue';
import BaseAlert from '../modules/common/components/argon/BaseAlert.vue'
import BaseButton from '../modules/common/components/argon/BaseButton.vue'
import BaseCheckbox from '../modules/common/components/argon/BaseCheckbox.vue'
import BaseInput from '../modules/common/components/argon/BaseInput.vue'
import BasePagination from '../modules/common/components/argon/BasePagination.vue'
import BaseProgress from '../modules/common/components/argon/BaseProgress.vue'
import BaseRadio from '../modules/common/components/argon/BaseRadio.vue'
import BaseSlider from '../modules/common/components/argon/BaseSlider.vue'
import BaseSwitch from '../modules/common/components/argon/BaseSwitch.vue'
import Card from '../modules/common/components/argon/Card.vue'
import Icon from '../modules/common/components/argon/Icon.vue'
import FormInput from '../modules/common/components/formControls/FormInput.vue';
import FormCheckbox from '../modules/common/components/formControls/FormCheckbox.vue';
import FormSpinbutton from '../modules/common/components/formControls/FormSpinbutton.vue';
import FormTextArea from '../modules/common/components/formControls/FormTextArea.vue';
import FormSelect from '../modules/common/components/formControls/FormSelect.vue';
import FormDateInput from '../modules/common/components/formControls/FormDateInput.vue';
import FormTimeInput from '@/modules/common/components/formControls/FormTimeInput.vue'
import FormRadio from '../modules/common/components/formControls/FormRadio.vue';
import BaseCard from '../modules/common/components//common/BaseCard.vue';
import BaseCollapse from '../modules/common/components/common/BaseCollapse.vue';
import BaseCollapseInvalid from '@/modules/common/components/common/BaseCollapseInvalid.vue';
import BaseDropdown from '../modules/common/components/common/BaseDropdown.vue';
import BaseTable from '../modules/common/components//common/BaseTable.vue';
import BaseModal from '../modules/common/components//common/BaseModal.vue';
import BaseSearchInput from '../modules/common/components//common/BaseSearchInput.vue';
import FieldTabs from '@/modules/common/components//common/FieldTabs.vue';
import FieldTab from '@/modules/common/components//common/FieldTab.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'leaflet/dist/leaflet.css';
import { App } from 'vue'

export default {
    install(app: App) {
        app.component(Badge.name, Badge)
        app.component(BaseAlert.name, BaseAlert)
        app.component(BaseButton.name, BaseButton)
        app.component(BaseInput.name, BaseInput)
        app.component(BaseCheckbox.name, BaseCheckbox)
        app.component(BasePagination.name, BasePagination)
        app.component(BaseProgress.name, BaseProgress)
        app.component(BaseRadio.name, BaseRadio)
        app.component(BaseSlider.name, BaseSlider)
        app.component(BaseSwitch.name, BaseSwitch)
        app.component(Card.name, Card)
        app.component(Icon.name, Icon)
        app.component(FormInput.name, FormInput)
        app.component(FormDateInput.name, FormDateInput)
        app.component(FormTimeInput.name, FormTimeInput)
        app.component(FormCheckbox.name, FormCheckbox)
        app.component(FormSpinbutton.name, FormSpinbutton)
        app.component(FormTextArea.name, FormTextArea)
        app.component(FormSelect.name, FormSelect)
        app.component(BaseCard.name, BaseCard)
        app.component(BaseCollapse.name, BaseCollapse)
        app.component(BaseCollapseInvalid.name, BaseCollapseInvalid)
        app.component(BaseDropdown.name, BaseDropdown)
        app.component(BaseTable.name, BaseTable)
        app.component(BaseSearchInput.name, BaseSearchInput)
        app.component(FieldTabs.name, FieldTabs)
        app.component(FieldTab.name, FieldTab)
        app.component(BaseModal.name, BaseModal)
        app.component(FormRadio.name, FormRadio)
        app.component('VueDatePicker', VueDatePicker);
    

    }
}
